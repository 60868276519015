<template>
  <div class="seat_wrap">
    <div class="seat_top">
      <span class="join" v-if="seat.status == 0">
        <i class="el-icon-user-solid pc-show"></i>
        <i class="el-icon-plus mobile-show" @click="seatReady(seat.code)"></i>
      </span>
      <img class="avatar" v-else :src="seat.avatar || ''" alt="" />
      <div class="name_price">
        <div class="nick_name">{{ seat.nickName }}</div>
        <price v-if="!showDetail">
          <countTo v-if="fightRoom.status == 1 || fightRoom.status == 0" :startVal="0" :endVal="countPrice" :duration="1500" :formatter="formatter" :decimals="2" />
          <countTo v-if="fightRoom.status == 2 || fightRoom.status == 3" :startVal="0" :endVal="totalPrize" :duration="1500" :formatter="formatter" :decimals="2" />
        </price>
        <price v-else>
          <countTo :startVal="0" :endVal="getTotalPrize" :duration="1500" :formatter="formatter" :decimals="2" />
        </price>
      </div>
      <div class="seat_loading mobile-show" v-if="fightRoom.status == 0 && seat.status != 1">
        <i class="el-icon-loading"></i>
        <span>等待中</span>
      </div>
      <div class="seat_loading mobile-show" v-if="fightRoom.status == 0 && seat.status == 1 && seat.playerId != $store.state.USER_INFO.userId">
        <i class="el-icon-loading"></i>
        <span>未准备</span>
      </div>
      <div class="seat_loading mobile-show" v-if="fightRoom.status == 0 && seat.status == 1 && seat.playerId == $store.state.USER_INFO.userId">
        <button class="goReady" v-if="seat.status == 1 && fightRoom.status == 0 && seat.playerId == $store.state.USER_INFO.userId" @click="handleAlert('准备',Ready)">准备</button>
      </div>
    </div>
    <div class="seat_center">
      <div class="seat_status pc-show">
        <div class="seat_loading" v-if="seat.status == 0">
          <i class="el-icon-loading" v-if="fightRoom.userId == userId"></i>
          <i class="el-icon-circle-plus-outline" @click="seatReady(seat.code)" v-else></i>
          <span class="room_seat_name">{{ fightRoom.userId == userId ? '等待中' : '加入战斗' }}</span>
        </div>
        <div class="seat_loading" v-if="seat.status == 2 && fightRoom.status == 0">
          <i class="el-icon-loading"></i>
          <span>等待中</span>
        </div>
        <div class="seat_loading" v-if="seat.status == 1 && fightRoom.status == 0">
          <i class="el-icon-loading"></i>
          <span>未准备</span>
        </div>
        <button class="goReady" v-if="seat.status == 1 && fightRoom.status == 0 && seat.playerId == $store.state.USER_INFO.userId" @click="handleAlert('准备',Ready)">准备</button>
      </div>
      <div class="lotteryRoundsDiv">
        <lotteryRounds v-if="fightRoom.status == 1" :records="detailList" :currentRound="currentRound" @allEnd="scrollStop" @produceResult="getLotteryResult" />
        <template v-if="fightRoom.status == 2 || fightRoom.status == 3">
          <div v-if="seat.isWinner" class="win_icon"></div>
          <!-- 失败显示的图标 -->
          <div v-else class="fail_icon"></div>
        </template>
      </div>
    </div>
    <div class="seat_bottom" v-if="realTimeResult.length || detailResult.length">
      <div class="goods_list">
        <template v-if="realTimeResult.length && fightRoom.status == 1">
          <goodsCard :key="index" :goodsItem="item" v-for="(item, index) in realTimeResult" :keyMap="{ levelImg: 'ornamentLevelImg', name: 'ornamentName', price: 'staticPrice' }" />
        </template>
        <template v-if="fightRoom.status == 2 || fightRoom.status == 3">
          <goodsCard :key="index" :goodsItem="item" v-for="(item, index) in detailResult" :keyMap="{ levelImg: 'ornamentLevelImg', name: 'ornamentName', price: 'staticPrice' }" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import goodsCard from '@/components/goodsCard.vue';
import lotteryRounds from '@/components/pkHome/lotteryRounds.vue';
import countTo from 'vue-count-to';
import { getUserInfo, ApiSeatReady,getBoxList } from '@/api/index';

export default {
  props: {
    seat: Object,
    playerId: Number,
    fightRoom: Object,
    fightResult: Map,
    showDetail: Boolean,
    currentRound: Number,
  },
  data() {
    return {
      //当前登录用户的id
      userId: '',
      //老虎机下面实时的结果列表
      realTimeList: [],
      //奖品价值之和
      playingAwards: 0,
      num: 0,
      currentBoxList:[],
      currentTotalMoney:0,
      boxPage: {
        page: 1,
        size: 10,
        gameMouldId: 2,
        orderByFie: 2,
      },
    };
  },
  components: {
    countTo,
    goodsCard,
    lotteryRounds,
  },
  created() {
    // 请求api获取用户信息
    this.userId = this.$store.state.USER_INFO.userId;
    if (this.userId) return;
    getUserInfo().then(res => {
      if (res?.data?.code == 200) {
        this.userId = res?.data?.data?.userId;
      } else {
        this.$message({
          offset: 50,
          message: '获取用户信息失败,请重新登陆',
          type: 'warning',
        });
      }
    });
  },
  mounted() {
    if (!this.currentRound) return;
    this.realTimeList = (this.fightResult.get(this.playerId) || []).slice(0, this.currentRound - 1);
    console.log('座位信息', this.seat);
  },
  computed: {
    // 对战结果列表
    resultList() {
      let list = [];
      this.fightResult.forEach(value => {
        list.push(...value);
      });
      return list.filter(item => item.holderUserId == this.playerId);
    },
    // 对战详情列表
    detailList() {
      return this.fightResult.get(this.playerId).filter(item => item.boxId);
    },
    detailResult() {
      return this.showDetail ? [...this.detailList] : [...this.resultList];
    },
    // 对战实时列表
    realTimeResult() {
      return this.shuffle(this.realTimeList);
    },
    totalPrize() {
      let total = this.resultList.reduce((currentValue, item) => {
        return item.staticPrice + currentValue;
      }, 0);
      return total;
    },
    getTotalPrize() {
      let total = this.detailList.reduce((currentValue, item) => {
        return item.staticPrice + currentValue;
      }, 0);
      return total;
    },
    countPrice() {
      let total = this.realTimeList.reduce((currentValue, item) => {
        return item.staticPrice + currentValue;
      }, 0);
      return total;
    },
  },
  methods: {
    handleAlert(btn, callback) {
      // this.currentTotalMoney = 0;
      const icon = require('../../assets/svgs/jinbi.svg');
      getBoxList(this.boxPage).then(res => {
        this.currentTotalMoney = 0;
        if (res.data.code == 200) {
          this.currentBoxList.push(...res.data.data.list);
        }
        if (res.data.data.list.length == this.boxPage.size) {
          this.boxPage.page++;
          this.handleAlert(btn, callback);
        } else {
          this.boxPage.page = 1;
          let key;
          for (key in this.fightRoom.boxData) {
            let arr = this.currentBoxList.filter(item => {
              return item.boxId == this.fightRoom.boxData[key].boxId;
            });
            if (arr.length == 0) {
              this.isError = true;
              return false;
            }
            this.currentTotalMoney += arr[0].price * this.fightRoom.boxData[key].number;
          }
          let msg = '';
          if (this.currentTotalMoney != this.fightRoom.boxPriceTotal) {
            msg = `<div class="message">箱子价格存在变动，确定消耗<span><img src="${icon}" />${this.currentTotalMoney}</span>吗？</div>`;
          } else {
            msg = `<div class="message">确定消耗<span><img src="${icon}" />${this.currentTotalMoney}</span>吗？</div>`;
          }
          this.$confirm(msg, '提示', {
            confirmButtonText: btn,
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            confirmButtonClass: 'active_btn',
            center: true,
          })
            .then(() => {
              callback?.(this.fightRoom.id,this.seat.code);
              this.currentBoxList = [];
              this.currentTotalMoney = 0;
            })
            .catch(() => {
              this.playAudio();
              this.currentBoxList = [];
              this.currentTotalMoney = 0;
            });
        }
      });
    },
    Ready(fightId, seatId) {
      ApiSeatReady({ fightId, seatId }).then(res => {
        if (res.data.code == 200) {
          getUserInfo().then(res => {
            this.$store.commit('USER_INFO', res.data.data);
          });

          this.isjoin = false;
        } else {
          this.$message({
            offset: 50,
            type: 'warning',
            message: res.data.msg,
          });
        }
      });
    },
    formatter() {},
    //准备游戏
    seatReady(id) {
      console.log('座位id2',id);
      
      this.$emit('seatReady', id);
    },
    shuffle(arr) {
      // let i = arr.length;
      // while (i) {
      //   let j = Math.floor(Math.random() * i--);
      //   [arr[j], arr[i]] = [arr[i], arr[j]];
      // }
      return arr;
    },
    getLotteryResult(res) {
      let r = null;
      for (let record of this.fightResult.get(this.playerId)) {
        if (record.ornamentId == res) {
          this.realTimeList.push(record);
          r = record;
          break;
        }
      }
      this.playingAwards = this.playingAwards + r.staticPrice;
    },
    scrollStop() {
      this.$emit('myAllEnd');
    },
  },
};
</script>

<style lang="less" scoped>
.goReady {
  border: 1px solid @main;
  margin-top: 10px;
  padding: 5px 15px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.219);
  .sc(24px,  @main);
  @media @max750 {
    margin: 0;
    padding: 3px 6px;
    .sc(12px,  @main);
  }
}
.seat_wrap {
  @media @max750 {
    .flex-column;
    height: 100%;
    overflow: hidden;
  }
  .seat_top {
    .flex-a-center;
    padding: 10px;
    background: #0d1214;
    @media @max750 {
      padding: 6px 0 2px;
      flex-direction: column;
      justify-content: center;
      border: 1px solid @main;
    }
    .join,
    .avatar {
      .wh(36px, 36px);
      border-radius: 50%;
      border: 1px solid #888;
      @media @max750 {
        .wh(34px, 34px);
        border: none;
        margin: 0 auto;
      }
    }
    .join {
      .flex-center;
      i {
        .sc(24px, #fff);
      }
      @media @max750 {
        .wh(32px, 32px);
        border: 1px dashed @main;
        i {
          .sc(22px, @main);
        }
      }
    }
    .name_price {
      width: 100%;
      .flex-a-center;
      @media @max750 {
        height: 36px;
        .flex-column-center;
      }
      .nick_name {
        flex: 1;
        padding: 0 10px;
        .sc(16px, #fff);
        @media @max750 {
          .ell;
          width: 90%;
          flex: none;
          padding: 0;
          margin: 0 auto;
          font-size: 12px;
          text-align: center;
          line-height: normal;
        }
      }
      .common_price {
        color: @jinbi;
        @media @max750 {
          line-height: 1;
          margin-top: 1px;
          transform: scale(0.9);
        }
      }
    }
    .seat_loading {
      @media @max750 {
        .sc(14px, #fff);
        text-align: center;
      }
    }
  }
  .seat_center {
    height: 300px;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
    @media @max750 {
      height: 80px;
    }
    .seat_status {
      .abs-ctl;
      z-index: 1;
      .seat_loading {
        .sc(20px, #fff);
        .flex-column-center;
        margin-top: -30px;
        i {
          font-size: 40px;
        }
        span {
          font-weight: bold;
          padding-top: 10px;
        }
      }
    }
    .lotteryRoundsDiv {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
      @media @max750 {
        background: url(../../assets/image/lottery.png) no-repeat;
        background-size: 100% 100%;
      }
      .lotteryRounds {
        position: relative;
      }
    }
  }
  .seat_bottom {
    padding: 14px 0 40px;
    overflow-x: hidden;
    @media @max750 {
      height: 56vh;
      padding: 5px;
      overflow-y: auto;
    }
    .goods_list {
      .grid(3, 6px);
      @media @max750 {
        .grid(2, 4px);
        .goods_item {
          overflow: hidden;
          border-radius: 4px;
          ::v-deep .goods_img {
            height: 40px;
          }
          ::v-deep .goods_bottom {
            padding: 2px 0;
            border-radius: 0 0 4px 4px;
            .goods_name {
              height: 8px;
              margin: 0 auto;
              font-size: 8px;
              text-align: center;
            }
            .common_price {
              font-size: 8px;
              transform: scale(0.8);
              .flex-center;
            }
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 10px;
    }
  }
}
.win_icon,
.fail_icon {
  .wh(100%, 100%);
  margin: 0 auto;
  @media @max750 {
    height: 80px;
  }
}
.win_icon {
  background: url('../../../public/win.gif') center no-repeat;
  background-size: 140% 140%;
  background-position: center 80%;
  @media @max750 {
    background-size: 190% 190%;
    background-position: center 66%;
  }
}
.fail_icon {
  background: url('../../../public/fail.gif') center no-repeat;
  background-size: 110% 110%;
  background-position: center 60%;
  @media @max750 {
    background-size: 150% 150%;
  }
}
</style>
