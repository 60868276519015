<template>
  <div class="root">
    <lottery class="lottery" :key="datekey" :data="roundParm.data" :res="roundParm.res" @scrollStop="onStop" @playPkAudio="playPkAudio" />
  </div>
</template>

<script>
import lottery from '@/components/pkHome/lottery.vue';

export default {
  props: {
    records: Array,
    currentRound: Number,
  },
  components: {
    lottery,
  },
  data() {
    return {
      // 一个人本局游戏所有数据
      allRecord: this.records,
      // 每回合参数
      roundParm: {
        data: [],
        res: '-1',
      },
      // 当前回合数
      round: this.currentRound,
      // 用于刷新子组件
      datekey: Date.now(),
      pkAudio: new Audio(require('../../../public/1.mp3')),
    };
  },
  mounted() {
    this.$bus.$emit('num', this.round);
    // 第一次渲染
    if (this.round == -1) return false;
    this.roundParm.data = this.allRecord[this.round - 1].boxInfo.ornaments;
    this.roundParm.res = this.allRecord[this.round - 1].ornamentId + '';
    // 重载lottery组件
    setTimeout(() => {
      this.datekey = Date.now(); // 重载lottery组件
    }, 100);
  },
  methods: {
    playPkAudio() {
      this.pkAudio.play();
    },
    onStop(result) {
      console.log('动画结束');
      // 产生结果
      this.$emit('produceResult', result);
      if (this.round >= this.allRecord.length) {
        console.log('执行结束函数');
        this.$emit('allEnd');
        return;
      }
      this.roundParm.data = this.allRecord[this.round].boxInfo.ornaments;
      this.roundParm.res = this.allRecord[this.round].ornamentId + '';
      this.round++;
      setTimeout(() => {
        this.$bus.$emit('num', this.round);
        this.datekey = Date.now(); // 重载lottery组件
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.root {
  width: 100%;
  height: 100%;
  position: absolute;
  .lottery {
    position: relative;
    height: 100%;
  }
}
</style>
