<template>
  <div class="root">
    <div class="parent" ref="parent">
      <div :class="transition" :style="cssVar">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="imgbox" :style="{ 'background-image': `url(${item?.levelImg})` }">
            <img :src="item?.imageUrl" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
    res: String,
  },
  data() {
    return {
      // 所有数据
      source: [...this.data],
      // 结果
      result: this.res,
      //抽奖结果对象
      target: {},
      //用于轮播的数据
      list: [],
      //控制动画播放的类名
      transition: '',
    };
  },
  mounted() {
    this.$emit('playPkAudio');
    this.data = this.add(this.data);
    this.list = [...this.data];
    this.target = this.source.filter(item => {
      return item.ornamentId == Number(this.res);
    });
    this.list.push(this.target[0]);
    let timer1 = setTimeout(() => {
      this.transition = 'transition';
      clearTimeout(timer1);
    }, 100);
    let timer2 = setTimeout(() => {
      this.$emit('scrollStop', this.result);
      clearTimeout(timer2);
    }, 3100);
  },
  computed: {
    cssVar() {
      return {
        '--H': 1 - this.list.length,
      };
    },
  },
  methods: {
    //宝箱翻倍
    add(arr) {
      if (arr.length < 10 && arr.length >= 1) {
        arr.push(...arr);
        return this.add(arr);
      } else {
        return arr;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.root {
  width: 100%;
  position: absolute;
}
.parent {
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  @media @max750 {
    width: 96%;
    padding: 0;
    height: 80px;
  }
}
.item {
  width: 90%;
  height: 260px;
  padding: 20px 0;
  margin: 0 auto;
  @media @max750 {
    height: 70px;
    padding: 5px 0;
  }
}
.imgbox {
  width: 100%;
  height: 100%;
  .flex-center;
  background-size: 100% 100%;
  img {
    .wh(80%, 80%);
  }
}
.transition {
  transform: translateY(calc(var(--H) * 300px));
  @media @max750 {
    transform: translateY(calc(var(--H) * 80px));
  }
  transition: all 3s ease;
}
</style>
